import { render, staticRenderFns } from "./NewCampaign.vue?vue&type=template&id=0ad24f5a&scoped=true&"
import script from "./NewCampaign.vue?vue&type=script&lang=ts&"
export * from "./NewCampaign.vue?vue&type=script&lang=ts&"
import style0 from "./NewCampaign.vue?vue&type=style&index=0&id=0ad24f5a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad24f5a",
  null
  
)

export default component.exports