





































import Vue from 'vue'

import NewCampaignLayout from '@/components/ADSManager/NewCampaignLayout.vue'
import CampaignAccountSelection from '@/components/ADSManager/NewCampaign/CampaignAccountSelection.vue'
import CampaignInitialSettings from '@/components/ADSManager/NewCampaign/CampaignInitialSettings.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'

import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Campaign',
  components: {
    CampaignAccountSelection,
    CampaignInitialSettings,
    NewCampaignLayout,
    BaseAlert
  },
  props: {
    campaign_id: {
      required: false,
      type: String
    },
    hoverCreateNew: Boolean,
    hoverUseExisting: Boolean
  },
  data () {
    return {
      ad_sets_option: { label: 'Create new Ad set', code: 'create_new' },
      ad_option: { label: 'Create new Ad', code: 'create_new' },
      selected_ad_sets: [],
      selected_ads: [],
      selected_campaign: null,
      selected_objective: null,
      selected_special_ad_categories: [],
      selected_ad_account: { label: 'Select Ad Account', code: null },
      campaign_name: null,
      user_names: [],
      agreement: false,
      type: 'new',
      options_new_exist_ad_sets: [
        { label: 'Create new Ad set', code: 'create_new' },
        { label: 'Use exist Ad set', code: 'use_exists' }
      ],
      options_new_exist_ads: [
        { label: 'Create new Ad', code: 'create_new' },
        { label: 'Use exist Ad', code: 'use_exists' }
      ],
      val: null,
      errorMessage: '',
      errorMessages: [],
      disabledSelects: false,
      errors: []
    }
  },
  watch: {
    selected_ad_account (newValue) {
      const account = this.getById(newValue.code)
      if (account && (!account.pages || !account.pages.length)) {
        if (!this.errors.find(v => v.key === 'notPage')) {
          this.errors.push({
            key: 'notPage',
            value: `You have no Facebook Page that represents your business in ads. A Page is required to run ads.
            Create one to help people find and interact with your brand or business or organization.`
          })
        }
      } else {
        this.errors = []
      }
    }
  },
  created () {
    if (this.$route.name === 'EditCampaign') this.disabledSelects = true
    // this.loadCampaigns({
    //   search: { 'include[0]': 'ad_sets', 'include[1]': 'ad_sets.ads', 'status[]': 'BUILDING' }
    // })
    const promises = [
      this.loadAccounts({ search: { service: 'facebook', target: 'ads_management', 'include[0]': 'pages' } }),
      this.load_special_ad_categories(),
      this.load_campaign_objective()
    ]
    Promise.all(promises).then(() => {
      if (this.campaign_id) {
        this.load_campaign({ campaignId: this.campaign_id, params: {} })
          .then(() => {
            this.campaign_name = this.getCampaign.name
            const objective = this.getObjective.find(o => o.id === this.getCampaign.objective)
            this.selected_objective = {
              label: objective.name,
              code: objective.id
            }
            const acc = this.getById(this.getCampaign.account_id)
            this.selected_ad_account = { code: acc.id, label: acc.name, external_id: acc.external_id }
            this.selected_special_ad_categories = this.getCampaign.special_ad_categories.map(c => {
              const category = this.getSpecialAdCategories.find(sc => sc.id === c)
              return {
                label: category.name,
                code: category.id
              }
            })
            if (this.selected_special_ad_categories.length > 0) {
              this.agreement = true
            }
          })
      }
      if (!this.campaign_id) {
        const acc = this.getById(localStorage.getItem('selectedAdAccount'))
        if (acc) {
          this.selected_ad_account = { code: acc.id, label: acc.name, external_id: acc.external_id }
        } else {
          this.selected_ad_account = {
            code: this.ad_accounts_names[0].code,
            label: this.ad_accounts_names[0].label,
            external_id: this.ad_accounts_names[0].external_id
          }
        }
      }
    })
  },
  computed: {
    ...mapGetters('adAccounts', ['getById', 'getAllAccounts']),
    ...mapGetters('campaigns', ['getAllCampaigns']),
    ...mapGetters('adsManager', ['getObjective', 'getSpecialAdCategories', 'getCampaign']),
    ad_accounts_names () {
      return this.getAllAccounts.filter(a => [1, 201, 1000].includes(+a.status)).map(i => {
        return {
          label: i.name,
          code: i.id,
          external_id: i.external_id
        }
      })
    },
    nextStepDisabled () {
      if (
        this.errors.length === 0 &&
        this.campaign_name !== null &&
        this.selected_objective !== null &&
        this.selected_ad_account.code !== null
      ) {
        return false
      } else return true
    },
    campaigns () {
      return this.getAllCampaigns.map(c => {
        return { label: c.name, code: c.id }
      })
    },
    ad_sets () {
      if (this.selected_campaign) {
        return this.getAllCampaigns
          .find(c => c.id === this.selected_campaign.code)
          ?.ad_sets.map(a => {
            return {
              label: a.name,
              code: a.id
            }
          })
      } else {
        return []
      }
    },
    ads () {
      if (this.selected_campaign) {
        return this.getAllCampaigns
          .find(c => c.id === this.selected_campaign.code)
          ?.ad_sets.flatMap(a => {
            return a.ads.map(ad => {
              return {
                label: ad.name,
                code: ad.id
              }
            })
          })
      } else {
        return []
      }
    },
    campaign_objective () {
      return this.getObjective.map(o => {
        return { label: o.name, code: o.id }
      })
    },
    special_ad_categories_options () {
      return this.getSpecialAdCategories.map(c => {
        return { label: c.name, code: c.id }
      })
    },
    selected_class () {
      return this.selected_ad_account ? '' : 'error'
    },
    create_campaign_data () {
      return {
        data: {
          name: this.campaign_name,
          objective: this.selected_objective?.code,
          account_id: this.selected_ad_account.code,
          special_ad_categories: (this.agreement) ? this.selected_special_ad_categories.map(c => c.code) : []
        },
        campaignId: this.campaign_id
      }
    },
    ad_account_select_style () {
      return {
        'font-size': '0.8rem',
        'line-height': 1,
        color: this.selected_ad_account?.code ? '#808181' : '#FA1C36'
      }
    }
  },
  methods: {
    ...mapActions('campaigns', ['loadCampaigns']),
    ...mapActions('adsManager', [
      'load_campaign_objective',
      'load_special_ad_categories',
      'store_campaign',
      'load_campaign'
    ]),
    ...mapActions('notifications', { addExceptionsToNotifications: 'addExceptions' }),
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('exceptions', ['addExceptions']),
    ...mapActions('adsManager/adDesign', ['reset_ad_design_data']),
    ...mapActions('adAccounts', ['loadAccounts']),
    selectAdAccount (event) {
      this.selected_ad_account = event
    },
    checkValue () {
      if (this.val.length === 0) {
        this.errorMessage = 'This field must not be empty'
      }
    },
    change_type (data) {
      this.type = data
    },
    nextClick () {
      this.store_campaign(this.create_campaign_data)
        .then(r => {
          this.$router.push({ name: 'NewCampaignAudience', params: { campaign_id: r.id } })
        })
        .catch(e => {
          if (e.response && e.response.data.errors) {
            this.addExceptionsToNotifications(e.response.data)
            this.addExceptions(e.response.data)
            this.addNotification({ body: e.response.data.errors[0].detail[0], type: 'danger' })
          }
        })
    },
    prevClick () {
      this.reset_ad_design_data()
      this.$router.push({
        name: 'AdsManager'
      })
    },
    store_campaign_name (data) {
      this.campaign_name = data.value
    }
  }
})
